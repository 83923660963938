<template>
  <div class="base-equipment-details">
    <vs-loader v-if="isLoading" />

    <b-container fluid v-else>
      <b-row>
        <b-col :cols="reportable ? '4' : '7'">
          <machine-details-card forEquipment />
        </b-col>

        <b-col cols="2">
          <base-cars-location-card
            :currentLocation="currentLocation"
            :futureLocations="futureLocations"
          />
        </b-col>

        <b-col cols="3">
          <base-cars-service-card :resource="equipment" type="equipment" />
        </b-col>

        <b-col cols="5">
          <machine-equipment-card
            forEquipment
            @attached:update="fetchEquipmentData"
          />
        </b-col>

        <base-service-create-modal />

        <template v-if="reportable">
          <b-col cols="6" v-can:show="'base.resource_report'">
            <machines-moto-hours-card v-if="equipment.id" />
          </b-col>

          <b-col cols="6">
            <machines-refueling-card v-if="equipment.id" />
          </b-col>
        </template>

        <b-col cols="7">
          <base-cars-schedule-card
            :resource="equipment"
            :currentLocation="currentLocation"
            :futureLocations="futureLocations"
          />
        </b-col>

        <b-col cols="6">
          <attachments-card v-if="files" :files="files" />
        </b-col>
      </b-row>
    </b-container>

    <machines-mth-report-modal
      :resourceEditable="false"
      type="tool"
      @ok="onReportCreate"
    />
  </div>
</template>

<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue';
import { computed, provide, ref } from 'vue';
import { useRouter } from '@core/utils/utils';
import MachinesMotoHoursCard from '@/components/views/base/machines/widgets/MachinesMotoHoursCard.vue';
import MachineDetailsCard from '@/components/views/base/machines/widgets/MachineDetailsCard.vue';
import MachinesRefuelingCard from '@/components/views/base/machines/widgets/MachineRefuelingCard.vue';
import MachineEquipmentCard from '@/components/views/base/machines/widgets/MachineEquipmentCard.vue';
import MachinesMthReportModal from '@/components/views/base/machines/modals/MachinesMthReportModal.vue';
import useBaseApi from '@/hooks/base/useBaseApi';
import useMachineMthCard from '@/hooks/base/machine/useMachineMthCard';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import BaseCarsLocationCard from '@/components/views/base/cars/widgets/BaseCarsLocationCard.vue';
import BaseCarsScheduleCard from '@/components/views/base/cars/widgets/BaseCarsScheduleCard.vue';
import BaseCarsServiceCard from '@/components/views/base/cars/widgets/BaseCarsServiceCard.vue';
import BaseServiceCreateModal from '@/components/views/base/service/modals/BaseServiceCreateModal.vue';
import AttachmentsCard from '@/components/views/base/resource/widgets/AttachmentsCard.vue';

export default {
  setup() {
    const { route } = useRouter();
    const equipmentId = computed(() => route.value.params.id);
    const equipment = ref({ tool_id: equipmentId.value });
    const resource = ref({ tool_id: equipmentId.value });
    const isLoading = ref(false);

    const fetchEquipmentData = () => {
      isLoading.value = true;
      const req = useBaseApi().fetchResource(equipmentId.value);

      req
        .then(({ data }) => {
          equipment.value = data.data;
        })
        .finally(() => {
          isLoading.value = false;
        });

      return req;
    };

    // TODO: get rid of machine after completing the migration to "resource"
    provide('machine', equipment);
    provide('resource', resource);

    const onReportCreate = () => {
      useMachineMthCard().updateChart(equipmentId.value, true);
    };

    const reportable = computed(() => equipment.value?.category?.be_reported);

    const currentLocation = ref(null);
    const futureLocations = ref([]);

    const fetchLocations = () => {
      useInvestmentsApi()
        .getAllLocations(equipment.value.id)
        .then(({ data }) => {
          currentLocation.value = data.data.current_location ?? null;
          futureLocations.value = data.data.future_locations.filter(
            (location) => location.id !== currentLocation.value?.id,
          ) ?? null;
        });
    };

    fetchEquipmentData().then(fetchLocations);

    const files = computed(() => equipment.value.files);

    return {
      equipment,
      fetchEquipmentData,
      isLoading,
      onReportCreate,
      reportable,
      currentLocation,
      futureLocations,
      files,
    };
  },
  components: {
    MachinesRefuelingCard,
    MachineEquipmentCard,
    MachinesMotoHoursCard,
    MachineDetailsCard,
    MachinesMthReportModal,
    BContainer,
    BRow,
    BCol,
    VsLoader,
    BaseCarsLocationCard,
    BaseCarsScheduleCard,
    BaseCarsServiceCard,
    BaseServiceCreateModal,
    AttachmentsCard,
  },
};
</script>
