var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-equipment-details"
  }, [_vm.isLoading ? _c('vs-loader') : _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": _vm.reportable ? '4' : '7'
    }
  }, [_c('machine-details-card', {
    attrs: {
      "forEquipment": ""
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('base-cars-location-card', {
    attrs: {
      "currentLocation": _vm.currentLocation,
      "futureLocations": _vm.futureLocations
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('base-cars-service-card', {
    attrs: {
      "resource": _vm.equipment,
      "type": "equipment"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('machine-equipment-card', {
    attrs: {
      "forEquipment": ""
    },
    on: {
      "attached:update": _vm.fetchEquipmentData
    }
  })], 1), _c('base-service-create-modal'), _vm.reportable ? [_c('b-col', {
    directives: [{
      name: "can",
      rawName: "v-can:show",
      value: 'base.resource_report',
      expression: "'base.resource_report'",
      arg: "show"
    }],
    attrs: {
      "cols": "6"
    }
  }, [_vm.equipment.id ? _c('machines-moto-hours-card') : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.equipment.id ? _c('machines-refueling-card') : _vm._e()], 1)] : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('base-cars-schedule-card', {
    attrs: {
      "resource": _vm.equipment,
      "currentLocation": _vm.currentLocation,
      "futureLocations": _vm.futureLocations
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.files ? _c('attachments-card', {
    attrs: {
      "files": _vm.files
    }
  }) : _vm._e()], 1)], 2)], 1), _c('machines-mth-report-modal', {
    attrs: {
      "resourceEditable": false,
      "type": "tool"
    },
    on: {
      "ok": _vm.onReportCreate
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }